import { toast } from 'react-toastify';

const emailjs = require('@emailjs/browser');

const {
  REACT_APP_EMAILJS_SERVICE_ID,
  REACT_APP_EMAILJS_PUBLIC_KEY,
  REACT_APP_EMAILJS_TEMPLATE_ID,
  REACT_APP_EMAILJS_TO_NAME,
} = process.env;

const missingVars = [
  !REACT_APP_EMAILJS_SERVICE_ID && 'SERVICE_ID',
  !REACT_APP_EMAILJS_PUBLIC_KEY && 'PUBLIC_KEY',
  !REACT_APP_EMAILJS_TEMPLATE_ID && 'TEMPLATE_ID',
].filter(Boolean);

if (missingVars.length) {
  console.error(`Missing required EmailJS variables: ${missingVars.join(', ')}`);
}

export const sendEmail = async ({ templateId, params, attachments }) => {
  const serviceId = REACT_APP_EMAILJS_SERVICE_ID;
  const selectedTemplateID = templateId || REACT_APP_EMAILJS_TEMPLATE_ID;
  
  const templateParams = {
    to_name: REACT_APP_EMAILJS_TO_NAME,
    from_name: params.name,
    ...params,
  };

  emailjs.init({ publicKey: REACT_APP_EMAILJS_PUBLIC_KEY });
  
  const emailAttachments = attachments?.length
    ? attachments.map((attachment) => ({ 
        name: attachment.name, 
        data: attachment.data 
      }))
    : [];

  try {
    const response = await emailjs.send(
      serviceId,
      selectedTemplateID,
      templateParams,
      emailAttachments,
      REACT_APP_EMAILJS_PUBLIC_KEY
    );
    
    console.log('Email sent successfully!', response);
    toast.success('Request Submitted successfully!');
    return response;
  } catch (error) {
    console.error('Error sending email:', error);
    toast.error('Something went wrong, please try again later');
    return error;
  }
};
