/* eslint-disable react/jsx-pascal-case */
/* eslint-disable no-shadow */
import axios from 'axios';
import ReCAPTCHA from 'react-google-recaptcha';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { Helmet } from 'react-helmet';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import React, { useState, useRef, useEffect } from 'react';
import Spinner from '../../components/Spinner';
import Success_post_dialog from '../../components/Success_post_dialog';
// import Button from '../../components/button/Button';
import '../../Styles/Styles.css';
import BrovitechLogger from '../../utils/BrovitechLogger';
import './contact.css';
import { Events, trackEvent, trackScreen } from '../../configs/Analytics';
import { sendEmail } from '../../utils/email';

function Contacts() {
  const [state, setState] = useState({
    name: '',
    email: '',
    text: '',
    isLoading: false,
  });
  useEffect(() => {
    // Track page view
    trackScreen('ContactScreen');
  }, []);
  const captchaRef = useRef(null);

  const [consult, setConsult] = useState('');
  const [capta, setCapta] = useState(false);
  const [verified, setVerified] = useState(false);

  const [error, setError] = useState(false);
  const [dialog, setDialog] = useState(false);
  const [phone, setPhone] = useState();
  const [phoneError, setPhoneError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [nameTextError, setNameError] = useState(false);

  const handlePhoneChange = (value) => {
    setPhone(value === '' ? undefined : value);
    setPhoneError(false); // Reset phone error when the user interacts with the phone input
  };

  const validateEmail = (email) => {
    const emailRegex = /^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,3})+$/;
    return emailRegex.test(email);
  };
  const validateText = (name) => 
     name.length > 0;
const handleInputChange = (event) => {
    const { name, value } = event.target;

    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    // Reset errors for email and name fields
    if (name === 'email') {
      setEmailError(false);
    } else if (name === 'name') {
      setNameError(false);
    }
  };

  const onChange = (value) => {
    setCapta(true);
    setVerified(true);
    BrovitechLogger.log('Captcha value:', value);

    BrovitechLogger.log(capta);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
      if (
        state.name.trim() === '' ||
        state.email.trim() === '' ||
        state.text.trim() === '' ||
        !consult ||
        !phone // Check if phone number is empty
      ) {
        toast.error('Please fill required fields correctly');
        setError(true);
        setState((prevState) => ({
          ...prevState,
          isLoading: false,
        }));
        return; // Do not proceed with the submission if there are errors
      }
  
      const isValidEmail = validateEmail(state.email);
      setEmailError(!isValidEmail);
      const isValidText = validateText(state.name);
      setNameError(!isValidText);
  
      const isValidPhone = phone.length > 5;
      setPhoneError(!isValidPhone);
  
      // Check for email and phone validity
      if (!isValidEmail || !isValidPhone || !isValidText) {
        toast.error('Please fill required fields correctly');
        setError(true);
        setState((prevState) => ({
          ...prevState,
          isLoading: false,
        }));
        return;
      }
  
      // Rest of the code for form submission
      try {
        setState((prevState) => ({
          ...prevState,
          isLoading: true,
        }));
        sendEmail({
          templateId: process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
          params: { ...state, phone, purpose: consult, message: state.text }
        });
  
        const response = await axios.post(
          'https://us-central1-updatedwebsite-d336b.cloudfunctions.net/projectQuery-projectQuery',
          {
            name: state.name,
            email: state.email,
            type: consult,
            message: state.text,
            phoneNo: phone,
          }
        );
  
        BrovitechLogger.log('call Successful:', response);
        trackEvent(Events.query_form_submission, {
          form_type: consult,
        });
  
        setState((prevState) => ({
          ...prevState,
          name: '',
          email: '',
          type: '',
          text: '',
          isLoading: false,
        }));
        setConsult('');
        setDialog(true);
        setPhone(undefined);
        setError(false);
      } catch (error) {
        BrovitechLogger.log('call error:', error);
        setState((prevState) => ({
          ...prevState,
          isLoading: false,
        }));
        trackEvent(Events.query_form_submission_error, {
          error_message: error.message,
        });
        alert(error);
      }
  };

  return (
    <>
      {/* <!-- Contact--> */}
      <section className="page-section" id="contact">

        <Helmet>
          <title>
            Contact Us - BROVITECH : Mobile App Development Company
          </title>
          <meta name="description" content="Contact us to know more about our services and quotation." />
          <link rel="canonical" href="https://brovitech.com/contacts" />

        </Helmet>
        <div className="container">
          <form id="contactForm" data-sb-form-api-token="API_TOKEN">
            {state.isLoading && <Spinner />}
            {dialog === true && <Success_post_dialog />}

            <div className="row align-items-stretch mb-5">
              <div className="col-md-6">
                <div className="form-group">
                  <h2 className="section-heading">Contact details</h2>

                  <div className="contact-pera">
                    <p>
                      Contact us to know more about our services and quotation.
                    </p>
                  </div>

                  <h5>Location</h5>
                  <div className="location-name">
                    <p>
                      {`Office 20, 8th Floor,
    B-wing, Down Town City Vista,
    Ashoka Nagar, Kharadi, 
    Pune, Maharashtra 411014, India`}
                    </p>
                  </div>
                  <h5>Business</h5>
                  <p className="emMob">
                    <a
                      href="mailto:business@brovitech.com"
                      style={{ textDecoration: 'none', color: '#0d6efd' }}

                    >
                      <FontAwesomeIcon style={{ color: 'black', marginRight: '10px' }} icon={faEnvelope} />

                      business@brovitech.com
                    </a>

                  </p>
                  <p className="emMob">
                    <a
                      href="tel:+919975452800"
                      style={{ textDecoration: 'none', color: '#0d6efd' }}
                    >
                      <FontAwesomeIcon style={{ color: 'black', marginRight: '10px' }} icon={faPhone} />
                      +91 9975452800
                    </a>
                  </p>

                  <h5>Careers</h5>
                  <p className="emMob">
                    <a
                      href="mailto:careers@brovitech.com
                      "
                      style={{ textDecoration: 'none', color: '#0d6efd' }}
                    >
                      <FontAwesomeIcon style={{ color: 'black', marginRight: '10px' }} icon={faEnvelope} />

                      careers@brovitech.com
                    </a>
                  </p>
                  {/* <h5>Phone</h5> */}
                  <p className="emMob">
                    <a
                      href="tel:+918788072163"
                      style={{ textDecoration: 'none', color: '#0d6efd' }}
                    >
                      <FontAwesomeIcon style={{ color: 'black', marginRight: '10px' }} icon={faPhone} />
                      +91 8788072163
                    </a>
                  </p>
                </div>
              </div>

              <div className="col-md-6" onSubmit={handleSubmit}>
                <div className="input-field-container form-group">
                  <h2 className="section-heading">Business Enquiry</h2>

                  <label className="labelReq" htmlFor="nameInput">
                    Your name (required)
                  </label>
                  <input
                    className="input"
                    label="Your name (required)"
                    name="name"
                    type="text"
                    value={state.name}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="error-print">

                  {error && state.name.length <= 0 ? (
                    <label
                      className="label"
                      htmlFor="nameInput"
                      style={{
                        color: 'red',
                        fontSize: '16px',
                        marginBottom: '10px'
                      }}
                    >
                      *Field is required
                    </label>
                  ) : (
                    nameTextError && (
                      <p style={{ color: 'red' }}>
                        *Please enter only alphabets in the name field
                      </p>
                    )
                  )}

                </div>
                <div
                  className="input-field-container form-group"
                  onSubmit={handleInputChange}
                >
                  <label className="labelReq" htmlFor="emailInput">Your Email (required)</label>
                  <input
                    className="input"
                    name="email"
                    type="email"
                    value={state.email}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="error-print">
                  {error && state.email.length <= 0 ? (
                    <label className="label" htmlFor="emailInput" style={{ color: 'red', marginBottom: '10px', fontSize: '16px' }}>
                      *Field is required
                    </label>
                  ) : (
                    emailError && (
                      <p style={{ color: 'red' }}>
                        *Please enter a valid email address
                      </p>
                    )
                  )}
                </div>
                <div className="input-field-container form-group">
                  <label className="labelReq" htmlFor="mobInput">
                    Your Mobile No. (required)
                  </label>

                  <PhoneInput
                    className="phone-input-container"
                    inputStyle="phone-input"
                    international
                    defaultCountry="IN"
                    value={phone}
                    onChange={handlePhoneChange}
                    placeholder="Enter your phone number"
                    countryCallingCodeEditable={false}
                  />

                  {error && !phone ? ( // Display error if phone is empty
                    <label className="label" htmlFor="mobInput" style={{ color: 'red', marginBottom: '10px', fontSize: '16px' }}>
                      *Field is required
                    </label>
                  ) : (
                    phoneError && (
                      <p style={{ color: 'red', marginTop: '5px' }}>
                        *Please enter a valid ten-digit Mobile Number
                      </p>
                    )
                  )}

                </div>

                <div className="form-group mb-md-0">
                  <label htmlFor="purpose_contact">
                    Purpose of contact (required)
                  </label>

                  <select
                    className="select-control"
                    selected
                    value={consult}
                    onChange={(event) => {
                      setConsult(event.target.value);
                    }}
                  >
                    <option value="">Select Purpose of Contact</option>
                    <option value="App Development">App Development</option>
                    <option value="Consulting">Consulting</option>
                    <option value="Software Solutions">Software Solutions</option>
                    <option value="Other">Other</option>
                  </select>
                </div>
                <div className="error-print">

                  {error && consult.length <= 0 ? (
                    <label className="label" htmlFor="nameInput" style={{ color: 'red', fontSize: '16px', marginBottom: '10px' }}>
                      *Field is required
                    </label>
                  ) : (
                    ''
                  )}
                </div>

                <label className="labelReq" htmlFor="msgInput">Your Message</label>

                <div className="form-group mb-md-0">
                  <textarea
                    className="textarea"
                    rows={3}
                    name="text"
                    type="text"
                    value={state.text}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="error-print">

                  {error && state.text.length <= 0 ? (
                    <label htmlFor="msgInput" style={{ color: 'red', fontSize: '16px' }}>*Field is required</label>
                  ) : (
                    ''
                  )}

                </div>
                <ReCAPTCHA
                  sitekey={process.env.REACT_APP_SITE_KEY}
                  ref={captchaRef}
                  onChange={onChange}
                  style={{
                    marginTop: '15px',
                    marginBottom: '15px',
                  }}
                />

                <div className="form-group mb-md-0">
                  <button
                    disabled={!verified}
                    type="submit"
                    onClick={(event) => {
                      handleSubmit(event);
                    }}
                    className="contactButton"
                  >
                    send
                  </button>

                </div>
              </div>
            </div>
          </form>
        </div>
      </section>
    </>
  );
}

export default Contacts;
